// import * as packageInfo from "../../package.json"
//token在storage存放名称
const TOKEN_NAME = "Authorization"
//接口请求的host地址
const HOST = process.env.VUE_APP_HOST || ""
const ENV = process.env.VUE_APP_ENV || ""
// kkpreview文件预览服务器地址
const PREVIEW_HOST = process.env.VUE_APP_PREVIEW_HOST || ""

let LOGIN_HOST = process.env.VUE_APP_LOGIN_HOST || ""
if (process.env.VUE_APP_ENV === "serve" && LOGIN_HOST === "") {
  // 本地调试
  // 在env中没有配置LOGIN_HOST
  // 跳转登录到本地登录
  LOGIN_HOST = window.location.origin
}

//网站首页的title
// 2022-08-18文案修改：智慧能源平台 => 京东方智慧能源操作系统

const WEB_TITLE =
  "京东方智慧能源管理系统从能源供、需两侧为客户提供多能供应、能源管理、能源建设、智能运维、节能服务、碳排放管理、碳交易等“源-网-荷”一体化的零碳综合能源解决方案。京东方能源是京东方集团智慧物联事业在能源领域落实物联网战略的重要实施平台。"

const NAV_TITLE = "京东方智慧能源管理系统"

const projectVersion = '1.0.0'

console.log("config.js", LOGIN_HOST, HOST, ENV, projectVersion, NAV_TITLE)

export { TOKEN_NAME, PREVIEW_HOST, LOGIN_HOST, HOST, ENV, WEB_TITLE, NAV_TITLE, projectVersion }
