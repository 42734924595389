const mainRouter = [
  {
    path: "home",
    name: "MainHome",
    component: () => import(/* webpackChunkName: "MainHome" */ "../../views/main/home"),
    meta: {
      title: "京东方智慧能源管理系统",
      main: "home",
    },
  },
  {
    path: "video/list",
    name: "MainVideoList",
    component: () => import(/* webpackChunkName: "MainVideoList" */ "../../views/main/home/video-list/index.vue"),
    meta: {
      title: "视频列表",
      main: "home",
    },
  },
  {
    path: "notice/list",
    name: "MainNoticeList",
    component: () => import(/* webpackChunkName: "MainNoticeList" */ "../../views/main/home/notice-list/index.vue"),
    meta: {
      title: "消息列表",
      main: "home",
    },
  },
  {
    path: "solution",
    name: "MainSolution",
    component: () => import(/* webpackChunkName: "MainSolution" */ "../../views/main/solution/index.vue"),
    meta: {
      title: "解决方案",
      main: "solution",
      needLogin: false,
    },
  },
  {
    path: "solution/list",
    name: "MainSolutionList",
    component: () => import(/* webpackChunkName: "MainSolutionList" */ "../../views/main/solution/list/index.vue"),
    meta: {
      title: "解决方案",
      main: "solution",
      needLogin: false,
    },
  },
  {
    path: "solution/detail",
    name: "MainSolutionDetail",
    component: () => import(/* webpackChunkName: "MainSolutionDetail" */ "../../views/main/solution/detail/index.vue"),
    meta: {
      title: "解决方案详情",
      main: "solution",
      needLogin: false,
    },
  },
  {
    path: "app-store",
    name: "MainApplicationStore",
    component: () => import(/* webpackChunkName: "MainApplicationStore" */ "../../views/main/app-store/index.vue"),
    meta: {
      title: "应用商店",
      main: "app-store",
    },
  },
  {
    path: "app-store/list",
    name: "MainApplicationStoreList",
    component: () =>
      import(/* webpackChunkName: "MainApplicationStoreList" */ "../../views/main/app-store/list/index.vue"),
    meta: {
      title: "应用商店",
      main: "app-store",
      needLogin: true,
    },
  },
  {
    path: "ecology",
    name: "MainEcology",
    component: () => import(/* webpackChunkName: "MainEcology" */ "../../views/main/ecology/index.vue"),
    meta: {
      title: "生态合作",
      main: "ecology",
    },
  },
  {
    path: "apply",
    name: "MainApply",
    component: () => import(/* webpackChunkName: "MainApply" */ "../../views/apply/index.vue"),
    meta: {
      title: "立即申请",
      main: "ecology",
    },
  },
  {
    path: "demand",
    name: "MainDemand",
    component: () => import(/* webpackChunkName: "MainDemand" */ "../../views/demand/index.vue"),
    meta: {
      title: "需求大厅",
      main: "demand",
      needLogin: false,
    },
  },
  {
    path: "demand/detail/:id",
    name: "MainDemandDetail",
    component: () => import(/* webpackChunkName: "MainDemandDetail" */ "../../views/demand/detail/index.vue"),
    meta: {
      title: "",
      main: "demand",
      needLogin: false,
    },
  },

  {
    path: "demand/release",
    name: "MainDemandRelease",
    component: () => import(/* webpackChunkName: "MainDemandRelease" */ "../../views/demand/release/index.vue"),
    meta: {
      title: "",
      main: "demand",
      needLogin: true,
    },
  },
  {
    path: "demand/release/:id",
    name: "MainDemandEdit",
    component: () => import(/* webpackChunkName: "MainDemandEdit" */ "../../views/demand/release/index.vue"),
    meta: {
      title: "",
      main: "demand",
      needLogin: true,
    },
  },
]
export default mainRouter
