export default {
  path: "personal",
  name: "PersonalIndex",
  component: () => import(/* webpackChunkName: "PersonalIndex" */ "../../views/personal/index.vue"),
  children: [
    {
      path: "profile",
      name: "MainPersonalProfile",
      component: () => import(/* webpackChunkName: "MainPersonalProfile" */ "../../views/personal/profile/index.vue"),
      meta: {
        title: "个人资料",
        main: "",
        personal: "profile",
        needLogin: true,
      },
    },
    {
      path: "order",
      name: "MainPersonalOrder",
      component: () => import(/* webpackChunkName: "MainPersonalOrder" */ "../../views/personal/order/index.vue"),
      meta: {
        title: "我的订单",
        main: "",
        personal: "order",
        needLogin: true,
      },
    },
    {
      path: "address",
      name: "MainPersonalAddress",
      component: () => import(/* webpackChunkName: "MainPersonalAddress" */ "../../views/personal/address/index.vue"),
      meta: {
        title: "我的地址",
        main: "",
        personal: "address",
        needLogin: true,
      },
    },
    {
      path: "demand",
      name: "MainDemandMy",
      component: () => import(/* webpackChunkName: "MainDemandMy" */ "../../views/demand/my/index.vue"),
      meta: {
        title: "我的需求",
        main: "",
        personal: "demand",
        needLogin: true,
      },
    },
  ],
}
