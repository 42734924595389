import axios from "axios"
import { getToken, removeToken, showMsgError, storageRemove } from "./index"
import { interceptorsRequest } from "@boe/utils-es"
import bus from "@/utils/event-bus"
import { HOST, LOGIN_HOST } from "./config.js"

import store from "@/store"
import router from "@/router"

const service = axios.create({
  // 直接请求当前域名的地址
  baseURL: HOST,
  timeout: 10 * 1000,
  onDownloadProgress(progress) {
    bus.$emit("on-download-progress", progress)
  },
  onUploadProgress(progress) {
    bus.$emit("on-upload-progress", progress)
  },
})
console.log("request.js", HOST, LOGIN_HOST)

service.interceptors.request.use(
  (config) => {
    return interceptorsRequest(config, "H@ppy1@3", { platform: "PC", token: getToken() })
  },
  (error) => {
    // do something with request error
    console.log(error, "err") // for debug
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  (response) => {
    const { data, headers } = response
    const contentType = headers["content-type"] || headers["Content-Type"]
    console.log("contentType", contentType)

    if (contentType === "application/json") {
      const { url } = response.config

      const res = response.data

      // console.log(res, "res")
      const { success, flag, errorMessage } = res

      if (!success && flag === "999") {
        // tokenDel用来删除后台的token信息
        // 在两个地方调用，一个是刚进入页面，判断token是否失效，失效了就删除token信息
        // 另一个是点击退出登录按钮，两个地方调用后都会返回flag 999，但是不需要进行跳转，直接在页面js进行操作
        if (!(url === "/bseos_interface/tokenDel" || url === "/bseos_interface/noticeList")) {
          //未登录，跳转到登陆页面

          removeToken()
          storageRemove("user")
          store.commit("userData/USER_LOGOUT")
          showMsgError("登录信息过期，请登录")
          // todo提示未登录弹框

          const backUrl = LOGIN_HOST.startsWith("//")
            ? `${window.location.protocol}${LOGIN_HOST}/openApp?appid=1`
            : `${LOGIN_HOST}/openApp?appid=1`
          let nowHref = window.location.href
          // console.log(nowHref, "nowHref")

          router.replace({
            name: "Login",
            query: {
              backUrl,
              bUrl: nowHref,
            },
          })
        }
      } else if (!success && errorMessage) {
        console.log("error")

        showMsgError(errorMessage)
      }

      return res
    } else {
      const res = response.data

      const contentDisposition = headers["content-disposition"] || headers["Content-Disposition"] || ""

      const deposition = decodeURIComponent(contentDisposition)
      const fileName = deposition.includes("filename*=utf-8''") ? deposition.split("filename*=utf-8''")[1] : ""
      const blob = new Blob([data], { type: contentType })

      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      window.URL.revokeObjectURL(link.href)
      return res
    }
  },
  (error) => {
    switch (error.response?.status) {
      case 400:
        error.message = "请求错误(400)"
        break
      case 401:
        error.message = "未授权(401)"
        break
      case 403:
        error.message = "拒绝访问(403)"
        break
      case 404:
        error.message = "请求出错(404)"
        break
      case 408:
        error.message = "请求超时(408)"
        break
      case 500:
        error.message = "服务器错误(500)"
        break
      case 501:
        error.message = "服务未实现(501)"
        break
      case 502:
        error.message = "网络错误(502)"
        break
      case 503:
        error.message = "服务不可用(503)"
        break
      case 504:
        error.message = "网络超时(504)"
        break
      case 505:
        error.message = "HTTP版本不受支持(505)"
        break
      default:
        error.message = `连接出错(${error.response?.status})!`
    }
    showMsgError(error.message)
    console.log("err" + error)
    return Promise.reject(error)
  },
)

export default service
