import { HOME_URL } from "@/utils"
export default {
  path: "/admin",
  name: "AdminLayout",
  redirect: "/admin/code",
  component: () => import(/* webpackChunkName: "AdminLayout" */ "../../components/AdminLayout/index.vue"),
  children: [
    {
      path: "code",
      name: "AdminCode",
      component: () => import(/* webpackChunkName: "AdminCode" */ "../../views/admin/code/index.vue"),
      meta: {
        title: "邀请码",
        activeKey: "code",
        needLogin: true,
        logoutUrl: HOME_URL,
        breadList: [
          {
            name: "成员管理",
            path: "",
          },
          {
            name: "邀请码",
            path: "/admin/code",
          },
        ],
      },
    },
    {
      path: "member",
      name: "AdminMember",
      component: () => import(/* webpackChunkName: "AdminMember" */ "../../views/admin/member/index.vue"),
      meta: {
        title: "成员列表",
        activeKey: "member",
        needLogin: true,
        logoutUrl: HOME_URL,
        breadList: [
          {
            name: "成员管理",
            path: "",
          },
          {
            name: "成员列表",
            path: "/admin/member",
          },
        ],
      },
    },
    {
      path: "invitation",
      name: "AdminInvitation",
      component: () => import(/* webpackChunkName: "AdminInvitation" */ "../../views/admin/invitation/index.vue"),
      meta: {
        title: "邀请列表",
        activeKey: "invitation",
        needLogin: true,
        logoutUrl: HOME_URL,
        breadList: [
          {
            name: "成员管理",
            path: "",
          },
          {
            name: "邀请列表",
            path: "/admin/invitation",
          },
        ],
      },
    },
    {
      path: "structure",
      name: "AdminStructure",
      component: () => import(/* webpackChunkName: "AdminStructure" */ "../../views/admin/structure/index.vue"),
      meta: {
        title: "组织架构",
        activeKey: "structure",
        needLogin: true,
        logoutUrl: HOME_URL,
        breadList: [
          {
            name: "成员管理",
            path: "",
          },
          {
            name: "组织架构",
            path: "/admin/structure",
          },
        ],
      },
    },
    {
      path: "application",
      name: "AdminApplication",
      component: () => import(/* webpackChunkName: "AdminApplication" */ "../../views/admin/application/index.vue"),
      meta: {
        title: "应用列表",
        activeKey: "application",
        needLogin: true,
        logoutUrl: HOME_URL,
        breadList: [
          {
            name: "应用管理",
            path: "",
          },
          {
            name: "应用列表",
            path: "/admin/application",
          },
        ],
      },
    },
    {
      path: "wish",
      name: "AdminWish",
      component: () => import(/* webpackChunkName: "AdminWish" */ "../../views/admin/wish/index.vue"),
      meta: {
        title: "心愿单",
        activeKey: "wish",
        needLogin: true,
        logoutUrl: HOME_URL,
        breadList: [
          {
            name: "成员管理",
            path: "",
          },
          {
            name: "心愿单",
            path: "/admin/wish",
          },
        ],
      },
    },
  ],
}
