const helpRouter = {
  path: "help",
  name: "HelpIndex",
  component: () => import(/* webpackChunkName: "HelpIndex" */ "../../views/help/index.vue"),
  redirect: "/help/desc",
  children: [
    {
      path: "desc",
      name: "MainHelpDesc",
      component: () => import(/* webpackChunkName: "MainHelpDesc" */ "../../views/help/desc/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "desc",
      },
    },
    {
      path: "sign-up",
      name: "MainHelpSignUp",
      component: () => import(/* webpackChunkName: "MainHelpSignUp" */ "../../views/help/sign-up/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "sign-up",
      },
    },
    {
      path: "forget",
      name: "MainHelpForget",
      component: () => import(/* webpackChunkName: "MainHelpForget" */ "../../views/help/forget/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "forget",
      },
    },
    {
      path: "personal",
      name: "MainHelpPersonal",
      component: () => import(/* webpackChunkName: "MainHelpPersonal" */ "../../views/help/personal/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "personal",
      },
    },
    {
      path: "why-certified",
      name: "HelpWhyCertified",
      component: () => import(/* webpackChunkName: "HelpWhyCertified" */ "../../views/help/why-certified/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "why-certified",
      },
    },
    {
      path: "how-certified",
      name: "HelpHowCertified",
      component: () => import(/* webpackChunkName: "HelpHowCertified" */ "../../views/help/how-certified/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "how-certified",
      },
    },
    {
      path: "partner-in",
      name: "HelpPartnerIn",
      component: () => import(/* webpackChunkName: "HelpPartnerIn" */ "../../views/help/partner-in/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "partner-in",
      },
    },
    {
      path: "open-shop",
      name: "HelpOpenShop",
      component: () => import(/* webpackChunkName: "HelpOpenShop" */ "../../views/help/open-shop/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "open-shop",
      },
    },
    {
      path: "policy",
      name: "HelpPolicy",
      component: () => import(/* webpackChunkName: "HelpPolicy" */ "../../views/help/policy/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "policy",
      },
    },
    {
      path: "agreement",
      name: "HelpAgreement",
      component: () => import(/* webpackChunkName: "HelpAgreement" */ "../../views/help/agreement/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "agreement",
      },
    },
    {
      path: "rule",
      name: "HelpRule",
      component: () => import(/* webpackChunkName: "HelpRule" */ "../../views/help/rule/index.vue"),
      meta: {
        title: "帮助中心",
        main: "home",
        help: "rule",
      },
    },
  ],
}
export default helpRouter
