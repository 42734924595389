export {
  storageEncrypt,
  CookieSet,
  CookieGet,
  CookieRemove,
  isObject,
  isArray,
  isNull,
  isUndefined,
  isString,
  isNumber,
  isDom,
  isObjectEmpty,
  getDomClientSize,
  getDomOffsetSize,
  getDomSize,
  setDomSize,
  getDomScrollTop,
  setDomScrollTop,
  setDomScrollToTop,
  getWindowsSize,
  getScrollTop,
  setScrollTop,
  scrollToTop,
  isScrollBottom,
  getBase64,
  getDocumentSize,
  UA,
  isAndroid,
  isIOS,
  isWeixin,
  isIE,
  isIE9,
  isEdge,
  isChrome,
  isFF,
  checkZipCode,
  checkPhone,
  checkAccount,
  checkPassword,
  checkMail,
  debounce,
  throttle,
  getMD5,
  objKeySort,
  noop,
  generateId as getUnionId,
  trim,
} from "@boe/utils-es"
import CryptoJS from "crypto-js"

export { UPLOAD_IMG_ACCEPT, HOME_URL } from "./enum"

import { noop, storageEncrypt } from "@boe/utils-es"

const { storageSet, storageGet, storageRemove } = storageEncrypt()

export { storageSet, storageGet, storageRemove }

export { isEqual } from "./is"
export { getToken, setToken, removeToken, checkIsLogin, toLoginWithUrl } from "./auth"
export { LOGIN_HOST, HOST, PREVIEW_HOST, TOKEN_NAME, ENV, WEB_TITLE, NAV_TITLE, projectVersion } from "./config"

import { message } from "ant-design-vue"

export function showMsgSuccess(content, duration = 1.2, cb = noop) {
  message.destroy()
  return message.success(content, duration, cb)
}

export function showMsgError(content, duration = 1.2, cb = noop) {
  message.destroy()
  return message.error(content, duration, cb)
}
export function showMsgInfo(content, duration = 1.2, cb = noop) {
  message.destroy()
  return message.info(content, duration, cb)
}
export function showMsgWarn(content, duration = 1.2, cb = noop) {
  message.destroy()
  return message.warn(content, duration, cb)
}

export function base64Encode(s) {
  var str = CryptoJS.enc.Utf8.parse(s)
  var base64 = CryptoJS.enc.Base64.stringify(str)

  return base64
}

export function base64Decode(s) {
  var words = CryptoJS.enc.Base64.parse(s)
  var parseStr = words.toString(CryptoJS.enc.Utf8)
  return parseStr
}

// 根据图片地址获取图片名称
// /djangoUpload/Bseos/good/1657178617004IMG202175-101338.jpg
export function getImgName(path) {
  if (path) {
    let temp = path.split("/")
    return temp[temp.length - 1]
  } else {
    return ""
  }
}
// 根据图片地址获取图片类型
export function getImgType(path) {
  if (path.endsWith(".jpg") || path.endsWith(".jpeg")) {
    return "image/jpeg"
  } else if (path.endsWith(".png")) {
    return "image/png"
  } else {
    return "image/png"
  }
}

export function getRandomString(len = 6) {
  if (len > 20) {
    len = 20
  }
  var t = "abcdefhijkmnprstwxyz",
    a = t.length,
    n = ""
  for (let i = 0; i < len; i++) {
    n += t.charAt(Math.floor(Math.random() * a))
  }
  return n
}

export function prohibitMenu() {
  //禁止debug调试
  /* eslint-disable-next-line */
  eval(
    (function (p, a, c, k, e, r) {
      e = function (c) {
        return c.toString(a)
      }
      if (!"".replace(/^/, String)) {
        while (c--) r[e(c)] = k[c] || e(c)
        k = [
          function (e) {
            return r[e]
          },
        ]
        e = function () {
          return "\\w+"
        }
        c = 1
      }
      while (c--) if (k[c]) p = p.replace(new RegExp("\\b" + e(c) + "\\b", "g"), k[c])
      return p
    })(
      '2 i=\'\',3=["e",""];(4(a){a[3[0]]=3[1]})(8);2 9=["g"];!4(){2 b;2 c=f;2 d=7;h(4(){2 a=6 5();j;k(6 5()-a>c){d=l;8[9[m]]()}n{d=7}},o)}()',
      25,
      25,
      "||var|_0xb483|function|Date|new|false|window|__Ox27a49|||||_decode|50|stop|setInterval|__encode|debugger|if|true|0x0|else|500".split(
        "|",
      ),
      0,
      {},
    ),
  )

  //屏蔽浏览器右键菜单
  if (window.Event) document.captureEvents(Event.MOUSEUP)
  function nocontextmenu() {
    event.cancelBubble = true
    event.returnValue = false
    return false
  }
  function norightclick(e) {
    if (window.Event) {
      if (e.which == 2 || e.which == 3) return false
    } else if (event.button == 2 || event.button == 3) {
      event.cancelBubble = true
      event.returnValue = false
      return false
    }
  }
  document.oncontextmenu = nocontextmenu // for IE5+
  document.onmousedown = norightclick // for all others
  //屏蔽浏览器右键菜单
  //屏蔽选中
  // 在正式环境影响cursor:pointer样式
  // document.onselectstart = function (event) {
  //   if (window.event) {
  //     event = window.event
  //   }
  //   try {
  //     var the = event.srcElement
  //     if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")) {
  //       return false
  //     }
  //     return true
  //   } catch (e) {
  //     return false
  //   }
  // }
  //屏蔽选中
}

// 去除后台给的富文本中的<div>等标签，
export function replaceTag(str) {
  return str.replace(/<.*?\/?>\s*/g, "")
}

// url 图片的http地址
export function loadImage(url) {
  return new Promise(function (resolve, reject) {
    let img = new Image()
    img.setAttribute("crossOrigin", "anonymous")
    img.onload = function () {
      // console.log(img.width, img.height, type);
      // let base64 = transformImageToBase64(img, type);
      resolve(url)
    }
    img.onerror = function () {
      resolve(url)
    }

    img.src = url
  })
}
