export default {
  namespaced: true,
  state: {
    pageNo: 1,
    pageSize: 10,
    // 行业分类
    industryClassify: [],
    industryMap: {},
    activeIndustry: -1,
    // 需求分类
    demandClassify: [],
    demandMap: {},
    activeDemand: -1,
    // 搜索关键词
    searchVal: "",
  },
  mutations: {
    SET_DATA(state, payload) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
  },
  actions: {
    async demo({ commit, state }) {},
  },
}
