import { fetchGetNoticeList } from "@/api/user"
import { projectVersion } from "@/utils"
export default {
  namespaced: true,
  state: {
    // 用户识别id
    userId: 0,
    // 租户公司的id
    tenantId: 0,
    // 租户公司的类型
    // 0个人 1公司类型
    tenantType: 1,
    username: "",
    avatar: "",
    // 是否已登录
    isLogin: false,
    noticeNum: 0,
    // 登录的时间戳
    loginTime: 0,
    // 是否展示管理控制台按钮
    // 空字符串不展示
    // 数字则进行跳转
    manageAdminId: "",
  },
  mutations: {
    SET_DATA(state, payload) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
    //用户登录
    USER_LOGIN(state, payload) {
      const { avatar = "", username = "", id, tenant_id, tenant } = payload
      state.avatar = avatar
      state.isLogin = true
      state.userId = id
      state.tenantId = tenant_id
      state.username = username
      state.loginTime = +new Date()
      state.tenantType = tenant ? tenant.type : 0
      // 前端监控用户身份
      window.localStorage.WM_USER_INFO = JSON.stringify({
        userId: id + "",
        userTag: tenant_id + "",
      })
    },
    //用户退出登录
    USER_LOGOUT(state) {
      state.username = ""
      state.avatar = ""
      state.isLogin = false
      state.noticeNum = 0
      state.loginTime = 0
      state.tenantId = 0
      state.userId = 0
      state.tenantType = 1
      state.manageAdminId = ""
    },
    //用户名修改
    UPDATA_USERNAME(state, username) {
      state.username = username
    },
  },
  actions: {
    getNoticeList({ commit }) {
      fetchGetNoticeList().then((res) => {
        const { success, data } = res
        if (success && data) {
          const { unreadcount, BSEOSback_ID = "" } = data
          commit("SET_DATA", {
            noticeNum: unreadcount,
          })
          commit("SET_DATA", {
            manageAdminId: BSEOSback_ID,
          })
        }
      })
    },
  },
}
