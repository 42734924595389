export default [
  {
    path: "/story",
    name: "ToMobile1",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/footprints",
    name: "ToMobile2",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/link",
    name: "ToMobile3",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/detail",
    name: "ToMobile4",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/page/login",
    name: "ToMobile5",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/page/register",
    name: "ToMobile6",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/page/password",
    name: "ToMobile7",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/page/aggregation",
    name: "ToMobile8",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
  {
    path: "/page/download/:id",
    name: "ToMobile9",
    component: () => import(/* webpackChunkName: "ToMobile" */ "../../views/mobile/index.vue"),
  },
]
