export default [
  {
    path: "solution/detail/boeet/:id",
    name: "BoeetSolution",
    component: () => import(/* webpackChunkName: "BoeetSolution" */ "../../views/solution/boeet.vue"),
    meta: {
      title: "综合能源解决方案",
      main: "solution",
      needLogin: false,
    },
  },
]
