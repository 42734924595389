const userRouter = {
  path: "/user",
  name: "LoginLayout",
  component: () => import(/* webpackChunkName: "LoginLayout" */ "../../components/LoginLayout/index.vue"),
  children: [
    {
      path: "/login",
      name: "Login",
      component: () => import(/* webpackChunkName: "Login" */ "../../views/user/login/index.vue"),
      meta: {
        title: "京东方智慧能源管理系统",
      },
    },
    {
      path: "/register",
      name: "Register",
      component: () => import(/* webpackChunkName: "Register" */ "../../views/user/register"),
      meta: {
        title: "注册",
      },
    },
    {
      path: "/password",
      name: "Password",
      component: () => import(/* webpackChunkName: "Password" */ "../../views/user/password"),
      meta: {
        title: "找回密码",
      },
    },
  ],
}
export default userRouter
