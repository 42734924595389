import Vue from "vue"
import VueRouter from "vue-router"
import qs from "qs"
// import Index from "../views/index.vue"
import mainRouter from "./module/main"
import userRouter from "./module/user"
import storeRouter from "./module/store"
import helpRouter from "./module/help"
import personalRouter from "./module/personal"
import hardwareRouter from "./module/hardware"
import adminRouter from "./module/admin"
import mobileRouter from "./module/mobile"
import newsRouter from "./module/news"
import activityRouter from "./module/activity"
import informationRouter from "./module/information"
import advertisementRouter from "./module/advertisement"
import solutionRouter from "./module/solution"

import { aboutUsHome, aboutUsNews } from "./module/aboutus"

import { checkIsLogin, LOGIN_HOST, ENV } from "@/utils"

// bugfix：点击进入重复路由问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/main/home",
    meta: {},
  },
  {
    path: "/index",
    name: "HomeIndex",
    redirect: "/main/home",
    meta: {
      title: "京东方智慧能源管理系统",
    },
  },
  {
    path: "/main",
    name: "MainLayout",
    component: () => import(/* webpackChunkName: "MainLayout" */ "../components/MainLayout/index.vue"),
    children: [
      ...mainRouter,
      ...newsRouter,
      ...activityRouter,
      ...advertisementRouter,
      ...storeRouter,
      ...aboutUsNews,
      ...informationRouter,
      ...solutionRouter,
      helpRouter,
      personalRouter,
      {
        path: "order/detail",
        name: "MainPersonalOrderDetail",
        component: () =>
          import(/* webpackChunkName: "MainPersonalOrderDetail" */ "../views/personal/order-detail/index.vue"),
        meta: {
          title: "订单详情",
        },
      },
    ],
  },
  {
    path: "/login/openApp",
    name: "OpenApp",
    component: () => import(/* webpackChunkName: "OpenApp" */ "../views/user/open-app/index.vue"),
  },
  userRouter,
  hardwareRouter,
  adminRouter,
  aboutUsHome,
  ...mobileRouter,
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
})
router.beforeEach((to, from, next) => {
  const { meta = {}, path = "/", query } = to
  const { title = "", needLogin = false } = meta
  console.log("title", title)
  if (title) {
    document.title = title
  }
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(["_setAutoPageview", false])
      window._hmt.push(["_trackPageview", "/#" + to.fullPath])
    }
  }

  // console.log(needLogin, to, checkIsLogin(), "check login")
  if (needLogin && !checkIsLogin()) {
    // 未登录情况
    const backUrl = LOGIN_HOST.startsWith("//")
      ? `${window.location.protocol}${LOGIN_HOST}/openApp?appid=1`
      : `${LOGIN_HOST}/openApp?appid=1`
    const queryStr = qs.stringify(query)
    const bUrl = LOGIN_HOST.startsWith("//")
      ? `${window.location.protocol}${LOGIN_HOST}${path}${queryStr ? `?${queryStr}` : ""}`
      : `${LOGIN_HOST}${path}${queryStr ? `?${queryStr}` : ""}`
    next({
      name: "Login",
      query: {
        backUrl,
        bUrl,
      },
    })
  } else {
    next()
  }
})

export default router
