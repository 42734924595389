export default [
  {
    path: "information",
    name: "InformationIndex",
    component: () => import(/* webpackChunkName: "InformationIndex" */ "@/views/information/index.vue"),
    meta: {
      main: "home",
      needLogin: true,
      title: "共享资料",
    },
  },
  {
    path: "information/list",
    name: "InformationList",
    component: () => import(/* webpackChunkName: "InformationList" */ "@/views/information/list/index.vue"),
    meta: {
      main: "home",
      needLogin: true,
      title: "共享资料",
    },
  },
  {
    path: "information/detail/:id",
    name: "InformationDetail",
    component: () => import(/* webpackChunkName: "InformationDetail" */ "@/views/information/detail/index.vue"),
    meta: {
      main: "home",
      needLogin: true,
      title: "共享资料详情",
    },
  },
]
