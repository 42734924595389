export const aboutUsHome = {
  path: "/main/about/us",
  name: "MainAboutUs",
  component: () => import(/* webpackChunkName: "MainAboutUs" */ "@/views/about-us/index.vue"),
  meta: {
    main: "about-us",
    needLogin: false,
    title: "关于我们",
  },
}

export const aboutUsNews = [
  {
    path: "about/us/news/:id",
    name: "MainAboutUsNews",
    component: () => import(/* webpackChunkName: "MainAboutUsNews" */ "@/views/about-us/news/index.vue"),
    meta: {
      main: "",
      needLogin: false,
      title: "新闻详情",
    },
  },
]
