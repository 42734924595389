import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import userData from "./modules/userData"
import cartData from "./modules/cartData"
import demand from "./modules/demand"
import solutions from "./modules/solutions"
import { saveVuexState, restoreVuexState } from "@boe/utils-es"

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    userData: state.userData,
    cartData: state.cartData,
  }),
  // storage: window.localStorage,
  saveState: saveVuexState("bseos_"),
  restoreState: restoreVuexState("bseos_"),
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userData,
    cartData,
    demand,
    solutions,
  },
  plugins: [vuexLocal.plugin],
})
