export default {
  path: "/hardware",
  name: "HadrwareLayout",
  redirect: "/hardware/home",
  component: () => import(/* webpackChunkName: "HadrwareLayout" */ "../../components/HadrwareLayout/index.vue"),
  children: [
    {
      path: "home",
      name: "HardwareHome",
      component: () => import(/* webpackChunkName: "HardwareHome" */ "../../views/hardware/home/index.vue"),
      meta: {
        title: "硬件商城",
      },
    },
    {
      path: "shop/:id",
      name: "HardwareShop",
      component: () => import(/* webpackChunkName: "HardwareShop" */ "../../views/hardware/shop/index.vue"),
      meta: {
        title: "店铺详情",
      },
    },
    {
      path: "shop/:id/:classify",
      name: "HardwareShopClassify",
      component: () => import(/* webpackChunkName: "HardwareShopClassify" */ "../../views/hardware/shop/index.vue"),
      meta: {
        title: "店铺详情",
      },
    },
    {
      path: "today",
      name: "HardwareToday",
      component: () => import(/* webpackChunkName: "HardwareToday" */ "../../views/hardware/today/index.vue"),
      meta: {
        title: "今日好货",
      },
    },
    {
      path: "search/:type/:keywords",
      name: "HardwareSearchList",
      component: () =>
        import(/* webpackChunkName: "HardwareSearchList" */ "../../views/hardware/search-list/index.vue"),
      meta: {
        title: "搜索",
      },
    },
    {
      path: "product-detail/:id",
      name: "HardwareProductDetail",
      component: () =>
        import(/* webpackChunkName: "HardwareProductDetail" */ "../../views/hardware/product-detail/index.vue"),
      meta: {
        title: "商品详情",
      },
    },
    {
      path: "product-detail/software/:id",
      name: "HardwareSoftwareProductDetail",
      component: () =>
        import(
          /* webpackChunkName: "HardwareSoftwareProductDetail" */ "../../views/hardware/product-detail/software.vue"
        ),
      meta: {
        title: "商品详情",
      },
    },
    {
      path: "product-detail/service/:id",
      name: "HardwareServiceProductDetail",
      component: () =>
        import(
          /* webpackChunkName: "HardwareServiceProductDetail" */ "../../views/hardware/product-detail/service.vue"
        ),
      meta: {
        title: "商品详情",
      },
    },
    {
      path: "order",
      name: "HardwareOrder",
      component: () => import(/* webpackChunkName: "HardwareOrder" */ "../../views/hardware/order/index.vue"),
      meta: {
        title: "确认订单",
        needLogin: true,
      },
    },
    {
      path: "pay",
      name: "HardwarePay",
      component: () => import(/* webpackChunkName: "HardwarePay" */ "../../views/hardware/pay/index.vue"),
      meta: {
        title: "支付",
        needLogin: true,
      },
    },
    {
      path: "pay-success",
      name: "HardwarePaySuccess",
      component: () =>
        import(/* webpackChunkName: "HardwarePaySuccess" */ "../../views/hardware/pay-success/index.vue"),
      meta: {
        title: "支付成功",
        needLogin: true,
      },
    },
    {
      path: "cart",
      name: "HardwareCart",
      component: () => import(/* webpackChunkName: "HardwareCart" */ "../../views/hardware/cart/index.vue"),
      meta: {
        title: "购物车",
        needLogin: true,
      },
    },
    {
      path: "favorite",
      name: "HardwareFavorite",
      meta: { needLogin: true, main: "product" },
      redirect: "favorite/product",
      component: () => import(/* webpackChunkName: "HardwareCart" */ "../../views/hardware/favorite/index.vue"),
      children: [
        {
          path: "product",
          name: "HardwareFavoriteProduct",
          meta: { title: "收藏夹商品", needLogin: true, main: "product" },
          component: () =>
            import(/* webpackChunkName: "HardwareFavoriteProduct" */ "../../views/hardware/favorite/product/index.vue"),
        },
        {
          path: "shop",
          name: "HardwareFavoriteShop",
          meta: { title: "收藏夹店铺", needLogin: true, main: "shop" },
          component: () =>
            import(/* webpackChunkName: "HardwareFavoriteShop" */ "../../views/hardware/favorite/shop/index.vue"),
        },
      ],
    },
    {
      path: "apply",
      name: "HardwareApply",
      redirect: "apply/fill",
      component: () => import(/* webpackChunkName: "HardwareApply" */ "../../views/hardware/apply/index.vue"),
      children: [
        {
          path: "fill",
          name: "HardwareFavoriteFill",
          component: () =>
            import(/* webpackChunkName: "HardwareFavoriteFill" */ "../../views/hardware/apply/fill/index.vue"),
          meta: {
            title: "申请开店",
            needLogin: true,
          },
        },
        {
          path: "create",
          name: "HardwareFavoriteCreate",
          component: () =>
            import(/* webpackChunkName: "HardwareFavoriteCreate" */ "../../views/hardware/apply/create/index.vue"),
          meta: {
            title: "入驻成功",
            needLogin: true,
          },
        },
        {
          path: "perfect",
          name: "HardwareFavoritePerfect",
          component: () =>
            import(/* webpackChunkName: "HardwareFavoritePerfect" */ "../../views/hardware/apply/perfect/index.vue"),
          meta: {
            title: "完善店铺信息",
            needLogin: true,
          },
        },
      ],
    },
    {
      path: "my-shop",
      name: "MyShopLayout",
      redirect: "my-shop/publish",
      component: () => import(/* webpackChunkName: "MyShopLayout" */ "../../views/hardware/my-shop/index.vue"),
      children: [
        {
          path: "publish",
          name: "HardwareMyShopPublish",
          component: () =>
            import(/* webpackChunkName: "HardwareMyShopPublish" */ "../../views/hardware/my-shop/publish/index.vue"),
          meta: {
            needLogin: true,
            activeShop: "publish",
            title: "发布商品",
          },
        },
        {
          path: "product",
          name: "HardwareMyShopProduct",
          component: () =>
            import(/* webpackChunkName: "HardwareMyShopProduct" */ "../../views/hardware/my-shop/product/index.vue"),
          meta: {
            needLogin: true,
            activeShop: "product",
            title: "商品管理",
          },
        },
        {
          path: "product/detail",
          name: "HardwareMyShopProductDetail",
          component: () =>
            import(
              /* webpackChunkName: "HardwareMyShopProductDetail" */ "../../views/hardware/my-shop/publish/index.vue"
            ),
          meta: {
            needLogin: true,
            activeShop: "product",
            title: "商品详情",
          },
        },
        {
          path: "order",
          name: "HardwareMyShopOrder",
          component: () =>
            import(/* webpackChunkName: "HardwareMyShopOrder" */ "../../views/hardware/my-shop/order/index.vue"),
          meta: {
            needLogin: true,
            activeShop: "order",
            title: "订单管理",
          },
        },
        {
          path: "order/detail",
          name: "HardwareMyShopOrderDetail",
          component: () =>
            import(
              /* webpackChunkName: "HardwareMyShopOrderDetail" */ "../../views/hardware/my-shop/order-detail/index.vue"
            ),
          meta: {
            needLogin: true,
            activeShop: "order",
            title: "订单详情",
          },
        },
        {
          path: "role",
          name: "HardwareMyShopAuthRole",
          component: () =>
            import(/* webpackChunkName: "HardwareMyShopAuthRole" */ "../../views/hardware/my-shop/auth/role.vue"),
          meta: {
            needLogin: true,
            activeShop: "role",
            title: "管理员角色配置",
          },
        },
        {
          path: "user",
          name: "HardwareMyShopAuthUser",
          component: () =>
            import(/* webpackChunkName: "HardwareMyShopAuthUser" */ "../../views/hardware/my-shop/auth/user.vue"),
          meta: {
            needLogin: true,
            activeShop: "user",
            title: "管理员用户配置",
          },
        },
        {
          path: "perfect",
          name: "HardwareMyShopPerfect",
          component: () =>
            import(/* webpackChunkName: "HardwareMyShopPerfect" */ "../../views/hardware/my-shop/perfect/index.vue"),
          meta: {
            needLogin: true,
            activeShop: "perfect",
            title: "完善店铺信息",
          },
        },
      ],
    },
  ],
}
