<template>
  <a-config-provider :locale="locale">
    <!-- 禁止选中 -->
    <div id="app" ref="app" class="no-select">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN"
import { getWindowsSize } from "@/utils"
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    }
  },
  methods: {
    // 对小屏幕进行缩放
    calcSize() {
      const { width } = getWindowsSize()
      if (width < 1366) {
        this.$refs.app.style["transform-origin"] = "left top"
        this.$refs.app.style["transform"] = `scaleX(${width / 1366})`
        this.$refs.app.style["overflow"] = "hidden"
        this.$refs.app.style["width"] = "1366px"
      } else {
        this.$refs.app.style["transform-origin"] = ""
        this.$refs.app.style["transform"] = ""
        this.$refs.app.style["overflow"] = "auto"
        this.$refs.app.style["width"] = "auto"
      }
    },
  },
  mounted() {
    this.calcSize()
    window.addEventListener("resize", this.calcSize)
  },
  beforeDestroy() {
    console.log("destroy")
  },
}
</script>
<style lang="scss"></style>
