export default [
  {
    path: "advertise/list",
    name: "AdvertisementList",
    component: () => import(/* webpackChunkName: "AdvertisementList" */ "@/views/advertisement/list/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "广告列表",
    },
  },
  {
    path: "advertise/detail",
    name: "AdvertisementDetail",
    component: () => import(/* webpackChunkName: "AdvertisementDetail" */ "@/views/advertisement/detail/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "广告详情",
    },
  },
]
