import request from "@/utils/request"

// 移入收藏夹
export function fetchGetAddShop(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/Hardware/addShop",
    data,
  })
}

// 加入购物车
export function fetchGetAddCar(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/Hardware/addCar",
    data,
  })
}

// 购物车列表
export function fetchGetShopList(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/Hardware/shoplist",
    data,
  })
}

// 取消收藏夹购物车
export function fetchGetDelShop(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/Hardware/delShop",
    data,
  })
}
// 支付订单
export function fetchGetPay(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/Hardware/payOrder",
    data,
  })
}
