import Vue from "vue"
import Antd from "ant-design-vue"
import moment from "moment"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VideoPlayer from "vue-video-player"
import VuePhotoZoomPro from "vue-photo-zoom-pro"
import "video.js/dist/video-js.css"
import VueAwesomeSwiper from "vue-awesome-swiper"
import Swiper2, { Navigation, Pagination, Mousewheel, Parallax } from "swiper"
import VueQrcode from "vue-qrcode"
import VueClipboard from "vue-clipboard2"
import "@/utils/register"
import VueLazyload from "vue-lazyload"
import NP from "number-precision"
import filters from "./filters"
// import * as packageInfo from "../package.json"

import "swiper/swiper-bundle.css"
import bus from "@/utils/event-bus"
import { ENV, storageGet, prohibitMenu } from "@/utils"

import { autoExit } from "./utils/auth"

// import getMenuList from "./menu"

// getMenuList()

// import * as Sentry from "@sentry/browser"
// import * as Integrations from "@sentry/integrations"
import "ant-design-vue/dist/antd.css"

//全局样式
import "@/styles/index.scss"

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(VideoPlayer)
Vue.use(VueAwesomeSwiper)
Vue.use(VuePhotoZoomPro)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "dist/error.png",
  loading: "/static/imgs/loading.gif",
  attempt: 2,
})

Vue.use(VueClipboard)

Swiper2.use([Navigation, Pagination, Mousewheel, Parallax])

Vue.prototype.$moment = moment
Vue.prototype.$bus = bus
Vue.prototype.$np = NP

Vue.component("vue-qrcode", VueQrcode)

if (ENV === "prod" || ENV === "pre") {
  let pwd = storageGet("command", false)
  if (pwd !== "iloveboe") {
    // 禁止下载资源
    prohibitMenu()
  }
}

// sentry监控，废弃
// if (ENV === "test") {
//   console.log("sentry init")
//   Sentry.init({
//     dsn: "http://16ff3ab1dad34324b3addddd256e9f2b@10.197.200.133:8030/2",
//     integrations: [new Integrations.Vue({ Vue, attachProps: true })],
//   })
// }

Object.keys(filters).map((filter) => {
  Vue.filter(filter, filters[filter])
})

autoExit()

window.projectVersion = '1.0.0'
Vue.config.errorHandler = (err, vm, info) => {
  console.log("errorHandler", err, vm, info)
  typeof window.wmReportVueError === "function" && window.wmReportVueError(err, vm, info)
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
