import { fetchGetShopList } from "@/api/cart"
import { isEqual } from "@/utils/index.js"
// import NP from "number-precision"
export default {
  namespaced: true,
  state: {
    // 全选状态 {0:空,1:半选，2:全选}
    checkAllState: 0,
    // 购物车商品数量
    cartCount: 0,
    // 购物车结算时，商品数量
    orderCount: 0,
    // 购物车结算时，商品金额
    orderAllMoney: 0,
    // 购物车列表
    cartList: [],
    // 订单列表
    orderList: [],
    // 订单支付
    payOrder: {},
    // 购物车空状态
    empty: true,
    // 批量删除
    allshopId: [],
    //批量移入收藏夹
    allgoodId: [],
  },
  getters: {
    orderNumber: (state) => {
      const { orderList } = state
      let number = 0
      orderList.forEach((item) => {
        const { goodlist } = item
        goodlist.forEach(() => {
          number++
        })
      })
      return number
    },
    orderAmount: (state) => {
      const { orderList } = state
      let amount = 0
      orderList.forEach((item) => {
        const { goodlist } = item
        goodlist.forEach((el) => {
          amount += Number(el.allmoney)
        })
      })
      return amount
    },
  },

  mutations: {
    //设置
    SET_DATA(state, payload) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
    //数组添加
    ADD_ITEM(state, payload) {
      const { key, index, value } = payload
      // state[key].push(value)
      state[key].splice(index, 0, value)
    },
    //数组删除
    REMOVE_ITEM(state, payload) {
      const { key, index } = payload
      state[key].splice(index, 1)
    },
    //数组修改
    SET_ITEM(state, payload) {
      const { index, key, value } = payload
      state[key].splice(index, 1, value)
    },
  },
  actions: {
    // 获取购物车初始化
    getCartCount({ commit }) {
      fetchGetShopList().then((res) => {
        const { success, data } = res
        if (success && data) {
          // 购物车有数据时
          const { cartCount, cartList } = this.state.cartData
          console.log(cartCount, cartList)
          if (data.length > 0) {
            let count = 0
            data.forEach((item) => {
              const { goodlist } = item
              goodlist.forEach(() => {
                count++
              })
            })
            console.log("fetchGetShopList", count, cartCount)
            commit("SET_DATA", {
              checkAllState: 0,
              empty: false,
              cartCount: count,
              cartList: data,
            })
            // if (count !== cartCount) {
            //   commit("SET_DATA", {
            //     checkAllState: 0,
            //     empty: false,
            //     cartCount: count,
            //     cartList: data,
            //   })
            // } else if (!isEqual(data, cartList)) {
            //   commit("SET_DATA", {
            //     checkAllState: 0,
            //     empty: false,
            //     cartCount: count,
            //     cartList: data,
            //   })
            // }
          } else {
            commit("SET_DATA", {
              checkAllState: 0,
              cartCount: 0,
              empty: true,
              cartList: [],
            })
          }
        }
      })
    },
  },
}
