import request from "@/utils/request"
//登录获取token
export function fetchGetToken(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/getToken",
    data,
  })
}
//发送手机验证码
export function fetchPhoneSms(data) {
  console.log("send", data)
  return request({
    method: "POST",
    url: "/bseos_interface/getSmsCode",
    data,
  })
}
// 发送邮件验证码
export function fetchMailSms(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/getMailCode",
    data,
  })
}
//注册第一步
export function fetchRegister0(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/reg0",
    data,
  })
}
//注册第二步
export function fetchRegister1(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/reg1",
    data,
  })
}
// 找回密码第一步
export function fetchPassword0(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/pwd_change_sms_code_0",
    data,
  })
}
// 找回密码第二步
export function fetchPassword1(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/pwd_change_sms_code_1",
    data,
  })
}
//个人中心修改手机号
export function fetchPhone(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/verifyOldPhone",
    data,
  })
}
export function fetchChangePhone(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/modifyProfile",
    data,
  })
}
//个人中心修改邮箱
export function fetchMail(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/verifyOldMail",
    data,
  })
}
//个人中心修改邮箱（通过手机号）
export function fetchMailByPhone(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/verifyOldMailPhone",
    data,
  })
}
//修改密码
export function fetchModifyPassWord(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/pwd_change",
    data,
  })
}
//忘记密码
export function fetchForgetPassWord(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/pwd_change_sms_code_1",
    data,
  })
}
//退出登录，删除token
export function fetchDelToken(data) {
  return request({
    method: "POST",
    url: "/bseos_interface/tokenDel",
    data,
  })
}
