export default {
  setOrderStatus(recivegood) {
    switch (recivegood) {
      case 0:
        return "待发货"
      case 1:
        return "已发货"
      case 2:
        return "已完成"
    }
  },
}
