export default [
  {
    path: "activity/list",
    name: "MainActivityList",
    component: () => import(/* webpackChunkName: "MainActivityList" */ "@/views/activity/list/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "活动列表",
    },
  },
  {
    path: "activity/detail/:id",
    name: "MainActivityDetail",
    component: () => import(/* webpackChunkName: "MainActivityDetail" */ "@/views/activity/detail/index.vue"),
    meta: {
      main: "home",
      needLogin: false,
      title: "活动详情",
    },
  },
]
