export default {
  namespaced: true,
  state: {
    list: [
      {
        id: "1",
        banner: "/images/solutions/solution1.png",
        title: "制造业零碳综合能源解决方案",
        subTitle:
          "——为各类制造工厂和园区提供全面的综合能源解决方案，致力于制造业用户零碳目标的达成和长期经济效益的实现",
        desc: "",
        splits: [
          "企业绿色制造体系构建",
          "能效&人效双重提升",
          "能源与资源循环利用",
          "能碳数据统筹融合",
          "支持实现双碳目标",
        ],
        list: [
          {
            title: "清洁能源供应",
            desc: "部署屋顶光伏发电、风电，光热、地源热泵等可再生能源系统，提高新能源利用比例，减少对传统能源的依赖。",
            tag: "源头脱碳",
            icon: "/images/solutions/icon1.png",
            image: "/images/solutions/clean.png",
            imgTitle: "传统能源依赖强",
            imgDesc: "高度依赖传统能源，可再生能源利用率低",
          },
          {
            title: "虚拟电厂服务",
            desc: "整合用户的源荷资源，参与电力市场的各项交易和响应服务，为用户带来更稳定低价的电力资源。",
            tag: "",
            icon: "/images/solutions/icon2.png",
            image: "/images/solutions/vpp.png",
            imgTitle: "企业用能成本高",
            imgDesc: "企业电、气、热的需求大，单价贵，能源成本较高",
          },
          {
            title: "节能降碳改造",
            desc: "专业分析工艺流程的用能特征，节能技术改造与现场工况相匹配，满足工艺需求的前提下降低能耗与碳排。",
            tag: "过程减碳",
            icon: "/images/solutions/icon3.png",
            image: "/images/solutions/reduce.png",
            imgTitle: "用能场景复杂",
            imgDesc: "工艺复杂且控制条件严格，对节能管理和改造要求高",
          },
          {
            title: "循环经济利用",
            desc: "将生产过程产生的废气、废水、废热收集，结合排放标准和工艺需求，开发高效梯级利用技术实现能源资源的最大化利用，降低排放。",
            tag: "",
            icon: "/images/solutions/icon4.png",
            image: "/images/solutions/loop.png",
            imgTitle: "循环利用率低",
            imgDesc: "产生很多废热废水，缺乏高效的回收利用手段",
          },
          {
            title: "数字化能碳管理",
            desc: "基于BES开发出能碳管理平台，对企业能源管理进行赋能，提升系统运营效率。",
            tag: "智慧管碳",
            icon: "/images/solutions/icon5.png",
            image: "/images/solutions/digitization.png",
            imgTitle: "能源管理薄弱",
            imgDesc: "能源体系建设不全面，管理协同性弱",
          },
          {
            title: "碳资产管理",
            desc: "提供碳盘查、碳规划和碳交易服务，为企业提供包括ISO在内的碳排放国际认证，为绿色供应链上下游企业提供认证服务，应对出口碳关税审查。",
            tag: "",
            icon: "/images/solutions/icon6.png",
            image: "/images/solutions/assets.png",
            imgTitle: "低碳转型困难",
            imgDesc: "缺乏清晰的低碳规划和碳资产管理，转型路径不明确",
          },
        ],
        plan: [
          {
            title: "行业头部企业实践",
            desc: "我们有行业头部企业数十年的应用和管理实践，有系统的项目实施和方法论的总结提升",
            image: "/images/solutions/plan1.png",
          },
          {
            title: "源网荷储一体化整体方案支撑",
            desc: "以“能源规划+能源管控+能源物联+方案集成”一体的技术布局，设置多个专业化团队，有效保障方案落地",
            image: "/images/solutions/plan2.png",
          },
          {
            title: "定制化的产品服务",
            desc: "根据客户需求和现场条件，提供定制化的解决方案和软件产品",
            image: "/images/solutions/plan3.png",
          },
          {
            title: "全生命周期管理",
            desc: "从项目设计到建设交付，再到持续运维管理，专业团队提供全周期的服务和支持",
            image: "/images/solutions/plan4.png",
          },
        ],
      },
      {
        id: "2",
        banner: "/images/solutions/solution2.png",
        title: "公共机构零碳综合能源解决方案",
        subTitle:
          "——为政府办公楼、学校、医院等提供全面的能源托管服务，以创新的能源管理策略应对双碳政策背景下的用能挑战",
        desc: "长效能源托管服务：改变公共机构长期的能源消费模式，将能源管理和优化工作委托给专业的能源服务公司，<br/>以业务创新带来增值服务，降低能源成本并实现可持续发展目标。 ",
        splits: [
          "可持续性目标实现",
          "稳定的长期合作关系",
          "创建绿色品牌与先进评优",
          "数智融合提高决策水平",
          "全员节能意识提升",
        ],
        list: [
          {
            title: "提升清洁能源比例",
            desc: "充分利用场地和地区资源禀赋，因地制宜实施光伏、光热、风能、地热等清洁能源开发，建设清洁高效能源体系。",
            tag: "源头脱碳",
            icon: "/images/solutions/icon1.png",
            image: "/images/solutions/ratio.png",
            imgTitle: "可再生能源利用限制",
            imgDesc: "公共机构在实施太阳能、风能等项目时面临资金、技术和空间的限制",
          },
          {
            title: "综合能源项目改造",
            desc: "针对重点用能系统，如空调、热水等进行节能改造，提升设备系统运行性能，降低能源和资源的使用成本，助力机构实现节能降碳目标。",
            tag: "过程减碳",
            icon: "/images/solutions/icon3.png",
            image: "/images/solutions/reform.png",
            imgTitle: "节能降碳目标实现难",
            imgDesc: "公共机构面临更严格的能耗定额标准考核，实现困难",
          },
          {
            title: "微电网建设",
            desc: "通过分布式发电、储能、充电桩等资源，发展园区微网系统，提高能源系统的灵活性和可靠性，实现能源供应的的本地化和抗干扰能力。",
            tag: "智慧管碳",
            icon: "/images/solutions/icon2.png",
            image: "/images/solutions/micro.png",
            imgTitle: "能源稳定性需求高",
            imgDesc: "医院、数据中心等关键设施对能源供应的稳定性和可靠性有极高要求",
          },
          {
            title: "能碳平台建设与升级",
            desc: "根据公共机构管理要求，基于智慧能源管理系统（BES）完善和建设能碳管理平台，融合综合能源建设与零碳服务内容，推动公共机构的绿色、智慧服务升级。",
            tag: "",
            icon: "/images/solutions/icon7.png",
            image: "/images/solutions/construct.png",
            imgTitle: "能源信息化建设不足",
            imgDesc: "公共机构水电管理缺乏统筹性平台，经费投入较少，升级困难",
          },
        ],
        plan: [
          {
            title: "专业化团队管理",
            desc: "我们有实际工厂、园区多年的运营经验，能够运用先进的技术和方法来优化能源使用",
            image: "/images/solutions/plan1.png",
          },
          {
            title: "风险控制",
            desc: "托管服务将由服务方确保节能措施的准确性，为公共机构有效控制风险",
            image: "/images/solutions/plan2.png",
          },
          {
            title: "灵活性和定制化",
            desc: "可以根据公共机构的特定需求和条件提供定制化的解决方案",
            image: "/images/solutions/plan3.png",
          },
          {
            title: "资金节约",
            desc: "通过托管，公共机构可减少前期投资，我们会提供必要的资金或融资方案来实施能源项目",
            image: "/images/solutions/plan4.png",
          },
        ],
      },
      {
        id: "3",
        banner: "/images/solutions/solution3.png",
        title: "交通建筑零碳综合能源解决方案",
        subTitle: "——为机场、车站、地铁站等设计综合的绿色低碳解决方案，助力交通建筑优化能源使用，降低能耗和碳排放",
        desc: "",
        splits: [
          "增加绿色能源使用与消纳",
          "降低用能强度",
          "能效&人效双重提升",
          "提升建筑数智化管理",
          "保持适宜的环境条件",
        ],
        list: [
          {
            title: "BIPV与光储充一体技术应用",
            desc: "充分利用交通建筑的立面和屋顶，提高空间利用率，结合地面车棚的光储充一体化建设，提供更多的清洁能源。",
            tag: "源头脱碳",
            icon: "/images/solutions/icon1.png",
            image: "/images/solutions/tech.png",
            imgTitle: "可再生能源利用有限",
            imgDesc: "建筑造型特点与区域管制，可再生能源利用受限",
          },
          {
            title: "节能技术和设备改造",
            desc: "提供LED照明、变频电机、蓄冷蓄热系统，高效热泵等技术降低建筑能源消耗。",
            tag: "过程减碳",
            icon: "/images/solutions/icon3.png",
            image: "/images/solutions/save.png",
            imgTitle: "单位能耗强度高",
            imgDesc: "固有的运行特征导致其能耗强度远超其他类型公共建筑",
          },
          {
            title: "冷热系统优化调控",
            desc: "应用数据挖掘技术，进行中短期冷热负荷预测，根据机理模型给出系统寻优策略，实现冷热供需平衡并能减少能源浪费。",
            tag: "",
            icon: "/images/solutions/icon8.png",
            image: "/images/solutions/optimize.png",
            imgTitle: "冷热供需难以平衡",
            imgDesc: "冷热负荷随环境、客流等因素波动大，供需平衡调节困难",
          },
          {
            title: "数字化能碳管理",
            desc: "利用京东方智慧能源管理系统，集成物联网技术，打通不同系统之间的信息屏障，实现一屏统管。",
            tag: "智慧管碳",
            icon: "/images/solutions/icon5.png",
            image: "/images/solutions/digitization.png",
            imgTitle: "系统和数据孤立",
            imgDesc: "缺乏有效系统集成，无法实时掌控全局，高效决策",
          },
          {
            title: "设备维保管理",
            desc: "应用数字孪生技术，开发设备系统的运维管理工具，提升整体运维效率。",
            tag: "",
            icon: "/images/solutions/icon9.png",
            image: "/images/solutions/maintenance.png",
            imgTitle: "设施维护困难",
            imgDesc: "缺乏专业团队，设备故障需要更及时的响应处理",
          },
        ],
        plan: [
          {
            title: "高效能源利用与成本控制",
            desc: "通过创新的节能技术和系统优化，实现能源使用效率的飞跃性提升",
            image: "/images/solutions/plan1.png",
          },
          {
            title: "数智化与运营管理创新",
            desc: "运用前沿的信息技术和智慧能源管理系统，实现能源管理的全面数字化和智能化",
            image: "/images/solutions/plan2.png",
          },
          {
            title: "个性化定制与全面服务保障",
            desc: "提供深度定制的解决方案，结合全生命周期的服务支持，确保项目从设计到运维的每个阶段都能获得专业、可靠的服务保障",
            image: "/images/solutions/plan3.png",
          },
          {
            title: "绿色低碳转型与环境责任",
            desc: "采用综合清洁能源解决方案，显著减少碳排放，推动交通建筑的绿色低碳转型",
            image: "/images/solutions/plan4.png",
          },
        ],
      },
    ],
  },
}
