const storeRouter = [
  {
    path: "app-store/detail/:id",
    name: "MainApplicationStoreDetail",
    component: () => import(/* webpackChunkName: "MainApplicationStoreDetail" */ "../../views/app-store/detail/index.vue"),
    meta: {
      title: "应用详情",
      main: "app-store",
    },
  },
  {
    path: "app-store/order",
    name: "MainAppStoreOrder",
    component: () => import(/* webpackChunkName: "MainAppStoreOrder" */ "../../views/app-store/order/index.vue"),
    meta: {
      main: "app-store",
      title: "订阅订单",
    },
  },
  {
    path: "app-store/success",
    name: "MainAppStoreSuccess",
    component: () => import(/* webpackChunkName: "MainAppStoreSuccess" */ "../../views/app-store/success/index.vue"),
    meta: {
      title: "下单成功",
      main: "app-store",
    },
  },
]
export default storeRouter
